unit Casata.WebCore.DocumentsForm;

interface

uses
  System.SysUtils, System.Classes, Vcl.Controls,

  JS, Web,

  WEBLib.WebCtrls, WEBLib.Forms, WEBLib.Controls,

  WebCore.Knockout,

  Sigma.Web.Types,
  Sigma.WebCore.Types,

  Casata.Constants,
  Casata.Files.ViewModel;

type
  TDocumentsForm = class(TForm)
    DocumentsContainerDiv: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FSelectedGridRow: integer;

    // -- AG-Grid declarations.
    FDocumentsGridOptions: TJSObject;
    FDocumentsGridAPI: TJSObject;
    FOnRowClicked: TRowClickEvent;

    procedure HandleRowClicked(rowId: integer);
    procedure SetOnRowClicked(const Value: TRowClickEvent);
  public
    { Public declarations }
    procedure InitialiseBindings(filesViewModel: TCasataFilesViewModel);
    procedure ShowDocuments(documentList: TJSArray);

    property OnRowClicked: TRowClickEvent read FOnRowClicked write SetOnRowClicked;
  protected procedure LoadDFMValues; override; end;

var
  DocumentsForm: TDocumentsForm;

implementation

const
  cTableContainerId = 'documents_table_div';

{$R *.dfm}

{ TDocumentsForm }

//------------------------------------------------------------------------------
procedure TDocumentsForm.HandleRowClicked(rowId: integer);
begin
  // -- This procedure is triggered from the grid.
  FSelectedGridRow := rowId;

  if assigned(FOnRowClicked) then
    FOnRowClicked(self, rowId);
end;
//------------------------------------------------------------------------------
procedure TDocumentsForm.InitialiseBindings(filesViewModel: TCasataFilesViewModel);
var
  e: TJSElement;
  ec: TJSElement;
begin
  e := document.getElementById('btn_new_document');
  e.setAttribute('data-bind', 'click: ShowUploadDocumentDialog; disable: _dialogModeActive');

  e := document.getElementById('btn_refresh_documents');
  e.setAttribute('data-bind', 'click: RefreshDocuments; enable: _canRefresh');

  e := document.getElementById('btn_download_document');
  e.setAttribute('data-bind', 'click: DownloadDocument; enable: _canDownload');

  e := document.getElementById('btn_delete_document');
  e.setAttribute('data-bind', 'click: DeleteDocument; enable: _canDelete');

  e := document.getElementById('input_select_file');
  e.setAttribute('data-bind', 'event: {change: SelectFile}');

  e := document.getElementById('document_location_select');
  e.setAttribute('data-bind', 'options: $root._blocks, optionsText: "BlockName", value: _fileUploadLocation');

  e := document.getElementById('document_upload_description');
  e.setAttribute('data-bind', 'value: _fileDescription');

  e := document.getElementById('btn_document_upload');
  e.setAttribute('data-bind', 'click: UploadSelectedFile; enable: _allowUpload');

  e := document.getElementById('btn_cancel_document_upload');
  e.setAttribute('data-bind', 'click: CancelUploadDocumentDialog; enable: _selectedFileLoaded');

  e := document.getElementById('documents_location_filter_select');
  e.setAttribute('data-bind', 'options: $root._blocks, optionsText: "BlockName", value: _selectedBlock');
  //e.setAttribute('data-bind', 'options: $root._blocks, optionsText: "BlockName", value: _selectedBlock, enable: $root._defaultNavigationMode');

  e := document.getElementById('document_detail_div');
  e.setAttribute('data-bind', 'using: FActiveObservableDocument');

  e := document.getElementById('document_description_text');
  e.setAttribute('data-bind', 'value: _docDescription, disable: true');


  // -- Bind the delete document dialog elements.
  e := document.getElementById('btn_confirm_document_delete');
  e.setAttribute('data-bind', 'click: ConfirmDeleteFile');

  e := document.getElementById('btn_cancel_document_delete');
  e.setAttribute('data-bind', 'click: CancelDeleteFile');

  // -- Bind the dialog header.
  e := document.getElementById('dialog_document_name');
  e.setAttribute('data-bind', 'text: _selectedFileName');


  // -- Bind the top-level view model using the container div in the form.
  ec := document.getElementById(cDocumentsFormContainerDiv);
  ko.ApplyBindings(filesViewModel, ec);
end;
//------------------------------------------------------------------------------
procedure TDocumentsForm.SetOnRowClicked(const Value: TRowClickEvent);
begin
  FOnRowClicked := Value;
end;
//------------------------------------------------------------------------------
procedure TDocumentsForm.ShowDocuments(documentList: TJSArray);
begin
  {*************** AG-Grid Code **************}
  asm
    this.FDocumentsGridAPI.setGridOption('rowData', documentList);
  end;
end;
//------------------------------------------------------------------------------
procedure TDocumentsForm.WebFormCreate(Sender: TObject);
var
  docContainerId: string;
  rowClickProc: TRowClickHandler;
begin
  FSelectedGridRow := 0;
  docContainerId := '#' + cTableContainerId;
  rowClickProc := @HandleRowClicked;

  {*************** AG-Grid Code **************}
  asm
    this.FDocumentsGridOptions = {
      rowData: [],
      columnDefs: [
        { field: "RowID", headerName: "RowID", hide: true },
        { field: "FileName", headerName: "File Name", suppressMovable: true, width: 400 },
        { field: "CreatedByName", headerName: "Added By", suppressMovable: true, width: 350 },
        { field: "Location", headerName: "Location", suppressMovable: true, width: 160 }
      ],
      rowSelection: 'single',
      onSelectionChanged: (event) => {
        const selectedData = this.FDocumentsGridAPI.getSelectedRows();
        if(Array.isArray(selectedData) && selectedData.length)
        {
          var id = selectedData[0].RowID;
          rowClickProc(id);
        }
      }
    };

    const documentsGridElement = document.querySelector(docContainerId);
    this.FDocumentsGridAPI = agGrid.createGrid(documentsGridElement, this.FDocumentsGridOptions);
  end;
end;

procedure TDocumentsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DocumentsContainerDiv := THTMLDiv.Create('documents_container_div');

  DocumentsContainerDiv.BeforeLoadDFMValues;
  try
    Name := 'DocumentsForm';
    Width := 731;
    Height := 487;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    DocumentsContainerDiv.SetParentComponent(Self);
    DocumentsContainerDiv.Name := 'DocumentsContainerDiv';
    DocumentsContainerDiv.Left := 36;
    DocumentsContainerDiv.Top := 26;
    DocumentsContainerDiv.Width := 661;
    DocumentsContainerDiv.Height := 445;
    DocumentsContainerDiv.ElementClassName := 'formcontainer vertical';
    DocumentsContainerDiv.HeightStyle := ssAuto;
    DocumentsContainerDiv.WidthStyle := ssAuto;
    DocumentsContainerDiv.ElementPosition := epIgnore;
    DocumentsContainerDiv.ElementFont := efCSS;
    DocumentsContainerDiv.HTML.BeginUpdate;
    try
      DocumentsContainerDiv.HTML.Clear;
      DocumentsContainerDiv.HTML.Add('      <div id="documents_header_div" class="formheader rounded">');
      DocumentsContainerDiv.HTML.Add('        <div id="documents_title_div" class="h-subcontainer left">');
      DocumentsContainerDiv.HTML.Add('          <span class="formheadertext" data-bind="i18n: '#39'documents'#39'">Urbanisation Documents</span>');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('        <div id="documents_controls_div" class="h-subcontainer right">');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_refresh_documents" class="x-btn primary x-circle small">');
      DocumentsContainerDiv.HTML.Add('            <i class="gg-sync"></i>');
      DocumentsContainerDiv.HTML.Add('          </button>');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_download_document" class="x-btn primary x-circle small">');
      DocumentsContainerDiv.HTML.Add('            <i class="gg-software-download"></i>');
      DocumentsContainerDiv.HTML.Add('          </button>');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_new_document" class="x-btn primary x-circle small">');
      DocumentsContainerDiv.HTML.Add('            <i class="gg-add"></i>');
      DocumentsContainerDiv.HTML.Add('          </button>');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_delete_document" class="x-btn primary x-circle small">');
      DocumentsContainerDiv.HTML.Add('            <i class="gg-math-minus"></i>');
      DocumentsContainerDiv.HTML.Add('          </button>');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('      </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('      <div id="documents_master_div" class="datamaster-large">');
      DocumentsContainerDiv.HTML.Add('        <div id="documents_table_div" class="tablecontainer ag-theme-quartz">');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        <div id="documents_nav_container_div" class="h-subcontainer spaced medium">');
      DocumentsContainerDiv.HTML.Add('          <div id="documents_view_options_div" class="h-subcontainer right">           ');
      DocumentsContainerDiv.HTML.Add('            <div id="documents_location_selector_div" class="x-select">');
      DocumentsContainerDiv.HTML.Add('              <label for="documents_location_filter_select" data-bind="i18n: '#39'location'#39'">Location</label>');
      DocumentsContainerDiv.HTML.Add('              <select id="documents_location_filter_select">');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('              </select>');
      DocumentsContainerDiv.HTML.Add('              <span class="focus"></span>');
      DocumentsContainerDiv.HTML.Add('            </div>');
      DocumentsContainerDiv.HTML.Add('          </div>');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('      </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('      <div class="datadetail-container-small" id="document_detail_div">');
      DocumentsContainerDiv.HTML.Add('        <div id="document_description_div" class="">');
      DocumentsContainerDiv.HTML.Add('          <div class="textarea-container">');
      DocumentsContainerDiv.HTML.Add('            <textarea id="document_description_text" rows="8" placeholder=" "></textarea>');
      DocumentsContainerDiv.HTML.Add('            <label for="document_description_text" class="placeholder" data-bind="i18n: '#39'description'#39'">Document Description</label>');
      DocumentsContainerDiv.HTML.Add('          </div>   ');
      DocumentsContainerDiv.HTML.Add('        </div>        ');
      DocumentsContainerDiv.HTML.Add('      </div>    ');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('      <dialog id="document_upload_dialog" class="modal bottom">');
      DocumentsContainerDiv.HTML.Add('        <div class="dialog-header">');
      DocumentsContainerDiv.HTML.Add('          <span class="dialog-title">Upload a Document</span>            ');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        <div id="document_upload_div" class="dialog-container large">');
      DocumentsContainerDiv.HTML.Add('          <div>');
      DocumentsContainerDiv.HTML.Add('            <input id="input_select_file" type="file">  ');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('            <div id="document_location_selector_div" class="x-select">');
      DocumentsContainerDiv.HTML.Add('              <label for="document_location_select" data-bind="i18n: '#39'location'#39'">Location</label>');
      DocumentsContainerDiv.HTML.Add('              <select id="document_location_select">');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('              </select>');
      DocumentsContainerDiv.HTML.Add('              <span class="focus"></span>');
      DocumentsContainerDiv.HTML.Add('            </div>             ');
      DocumentsContainerDiv.HTML.Add('          </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('          <div id="document_upload_body">');
      DocumentsContainerDiv.HTML.Add('            <div class="textarea-container">');
      DocumentsContainerDiv.HTML.Add('              <textarea id="document_upload_description" rows="8" placeholder=" "></textarea>');
      DocumentsContainerDiv.HTML.Add('              <label for="document_upload_description" class="placeholder">File Description</label>');
      DocumentsContainerDiv.HTML.Add('            </div>');
      DocumentsContainerDiv.HTML.Add('          </div>          ');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        <div id="document_upload_footer" class="dialog-footer">');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_document_upload"  class="x-btn dialog primary">Upload</button>');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_cancel_document_upload"  class="x-btn dialog primary">Cancel</button>');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('      </dialog>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('      <dialog id="delete_document_dialog" class="modal centre">');
      DocumentsContainerDiv.HTML.Add('        <div class="dialog-header">');
      DocumentsContainerDiv.HTML.Add('          <span class="dialog-title" data-bind="i18n: '#39'deleteselecteddocument'#39'">Delete Selected Document:&nbsp;</span>   ');
      DocumentsContainerDiv.HTML.Add('          <span id="dialog_document_name" class="dialog-title"></span>         ');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        <div id="delete_document_dialog_div" class="dialog-container small fixed-width-large" data-bind="i18n: '#39'confirmdocumentdeletion'#39'">');
      DocumentsContainerDiv.HTML.Add('          Delete the selected document: Are you sure?              ');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('');
      DocumentsContainerDiv.HTML.Add('        <div id="delete_document_dialog_footer" class="dialog-footer">');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_confirm_document_delete"  class="x-btn dialog primary" data-bind="i18n: '#39'confirm'#39'">Ok</button>');
      DocumentsContainerDiv.HTML.Add('          <button id="btn_cancel_document_delete"  class="x-btn dialog primary" data-bind="i18n: '#39'cancel'#39'">Cancel</button>');
      DocumentsContainerDiv.HTML.Add('        </div>');
      DocumentsContainerDiv.HTML.Add('      </dialog>');
    finally
      DocumentsContainerDiv.HTML.EndUpdate;
    end;
    DocumentsContainerDiv.Role := '';
  finally
    DocumentsContainerDiv.AfterLoadDFMValues;
  end;
end;

end.