unit Sigma.WebCore.BaseDiscussionPostsForm;

interface

uses
  WEBLib.Forms;

type
  TBaseDiscussionPostsForm = class(TForm)

  end;

implementation

end.
