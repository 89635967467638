unit Casata.WebCore.WelcomeForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.WebCtrls;

type
  TWelcomeForm = class(TForm)
    ContentDiv: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;



implementation

{$R *.dfm}

procedure TWelcomeForm.WebFormCreate(Sender: TObject);
begin
  //inherited;
  //Initialise;
  //ContentDiv.EventStopPropagation := ContentDiv.EventStopPropagation - [eeMouseMove];
end;

procedure TWelcomeForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ContentDiv := THTMLDiv.Create('welcome_container_div');

  ContentDiv.BeforeLoadDFMValues;
  try
    Name := 'WelcomeForm';
    Width := 621;
    Height := 522;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    ContentDiv.SetParentComponent(Self);
    ContentDiv.Name := 'ContentDiv';
    ContentDiv.Left := 28;
    ContentDiv.Top := 24;
    ContentDiv.Width := 573;
    ContentDiv.Height := 469;
    ContentDiv.ElementClassName := 'basecontainer clientalign text-container translate';
    ContentDiv.HeightStyle := ssAuto;
    ContentDiv.WidthStyle := ssAuto;
    ContentDiv.ChildOrder := 2;
    ContentDiv.ElementPosition := epIgnore;
    ContentDiv.ElementFont := efCSS;
    ContentDiv.HTML.BeginUpdate;
    try
      ContentDiv.HTML.Clear;
      ContentDiv.HTML.Add('      <h4>Welcome to Casata</h4>');
      ContentDiv.HTML.Add('      <p>Casata is a private social network for property owners designed to give you control over how your home is managed and maintained.</p>');
      ContentDiv.HTML.Add('      <p>It has been primarily designed to manage groups of properties at a common location, such as apartment blocks and urbanisations. However even owners of individual properties will find it useful, especially such functionality such as the contractors directory.</p>');
      ContentDiv.HTML.Add('      <p>Casata is still in development and what you see here is an initial preview.</p>');
      ContentDiv.HTML.Add('      <p>Functionality in the first release will include:</p>');
      ContentDiv.HTML.Add('      <ul style="padding-left: 1rem">');
      ContentDiv.HTML.Add('        <li>Contractor directory and management</li>');
      ContentDiv.HTML.Add('        <li>Issue Management</li>');
      ContentDiv.HTML.Add('        <li>Issue Discussion Board</li>');
      ContentDiv.HTML.Add('        <li>Job Management</li>');
      ContentDiv.HTML.Add('        <li>Job Discussion Board</li>');
      ContentDiv.HTML.Add('        <li>Job Voting</li>');
      ContentDiv.HTML.Add('        <li>Job Quotation Management</li>');
      ContentDiv.HTML.Add('        <li>Announcements Board</li>');
      ContentDiv.HTML.Add('      </ul>');
      ContentDiv.HTML.Add('');
    finally
      ContentDiv.HTML.EndUpdate;
    end;
    ContentDiv.Role := '';
  finally
    ContentDiv.AfterLoadDFMValues;
  end;
end;

end.