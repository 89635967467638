unit Web.JSTypes;



interface

uses
  JS;

type
  TRawBlob = JSValue;

implementation

end.
