unit Casata.JobWizardPage4;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls;

type
  TJobWizardProvidersPage = class(TForm)
    JobWizardProvidersPageDiv: THTMLDiv;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  JobWizardProvidersPage: TJobWizardProvidersPage;

implementation

{$R *.dfm}

procedure TJobWizardProvidersPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  JobWizardProvidersPageDiv := THTMLDiv.Create('jobproviderspage_controls_div');

  JobWizardProvidersPageDiv.BeforeLoadDFMValues;
  try
    Name := 'JobWizardProvidersPage';
    Width := 775;
    Height := 478;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    JobWizardProvidersPageDiv.SetParentComponent(Self);
    JobWizardProvidersPageDiv.Name := 'JobWizardProvidersPageDiv';
    JobWizardProvidersPageDiv.Left := 12;
    JobWizardProvidersPageDiv.Top := 8;
    JobWizardProvidersPageDiv.Width := 745;
    JobWizardProvidersPageDiv.Height := 449;
    JobWizardProvidersPageDiv.ElementClassName := 'wizard-controls-container';
    JobWizardProvidersPageDiv.HeightStyle := ssAuto;
    JobWizardProvidersPageDiv.WidthStyle := ssAuto;
    JobWizardProvidersPageDiv.ElementPosition := epIgnore;
    JobWizardProvidersPageDiv.ElementFont := efCSS;
    JobWizardProvidersPageDiv.HTML.BeginUpdate;
    try
      JobWizardProvidersPageDiv.HTML.Clear;
      JobWizardProvidersPageDiv.HTML.Add('          <div id="job_wizard_providers_div" class="x-select">');
      JobWizardProvidersPageDiv.HTML.Add('            <label for="job_providers_list">Providers</label>');
      JobWizardProvidersPageDiv.HTML.Add('            <select id="job_providers_list" size="12">');
      JobWizardProvidersPageDiv.HTML.Add('');
      JobWizardProvidersPageDiv.HTML.Add('            </select>');
      JobWizardProvidersPageDiv.HTML.Add('            <span class="focus"></span>');
      JobWizardProvidersPageDiv.HTML.Add('          </div>');
    finally
      JobWizardProvidersPageDiv.HTML.EndUpdate;
    end;
    JobWizardProvidersPageDiv.Role := '';
  finally
    JobWizardProvidersPageDiv.AfterLoadDFMValues;
  end;
end;

end.